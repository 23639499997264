import {
  EditorReadyFn,
  EditorSDK,
  EventTypeStrings,
  ExportsStatic,
  GetAppManifest,
  PageRef,
} from '@wix/platform-editor-sdk';
import { IntegrationApplication, MembersAreaAppsOptions } from '@wix/members-area-app-definitions';
import { IntegrationApplicationMap } from '@wix/members-area-integration-kit';
import { MemberPrivacySettings } from '@wix/ambassador-members-v1-member-privacy-settings/types';

import { BiData, ReferralInfo } from '../../types/bi';
import { PagesModificationPayload as ConfigureMAAppsOptions } from '../../types/general-settings';
import { ProfileType } from '../services/profile';

export enum MembersAreaEventType {
  renameRouter = 'renameRouter',
  removePage = 'removePage',
  uninstall = 'uninstall',
  managePages = 'managePages',
  createBlankPage = 'createBlankPage',
  generalSettings = 'generalSettings',
  memberPrivacyDashboard = 'memberPrivacyDashboard',
}

type MembersAreaEventTypeStrings = keyof typeof MembersAreaEventType | EventTypeStrings;

export type MembersAreaOnEventFn = (
  options: { eventType: MembersAreaEventTypeStrings; eventPayload: any },
  editorSDK: EditorSDK,
) => Promise<void>;

export interface MembersAreaPlatformApp {
  editorReady: EditorReadyFn;
  getAppManifest: GetAppManifest;
  onEvent: MembersAreaOnEventFn;
}

export interface MembersAreaPublicApi extends ExportsStatic {
  addApplications: (
    applications: IntegrationApplication[],
    shouldNavigate: boolean,
    options?: { biData: BiData },
  ) => Promise<void>;
  getMembersPageRef: (page: { appDefinitionId: string; appPageId: string }) => Promise<PageRef | undefined>;
  removeMembersAreaPage: (pageId: string, appDefinitionId: string) => Promise<void>;
  setHorizontalLayout: (pwHeight: number) => Promise<void>;
  setSidebarLayout: () => Promise<void>;
  _getIsResponsiveEditor: () => Promise<boolean>;
  handleVerticalDeletion: (verticalAppDefId: string) => Promise<void>;
  registerMembersAreaApps: (
    applications: IntegrationApplication[],
    verticalAppDefId: string,
    applicationsOptions: MembersAreaAppsOptions,
  ) => Promise<void>;
  installRegisteredApps: (verticalAppDefId: string, options: { biData?: BiData }) => Promise<void>;
  getRegisteredApps: () => Promise<IntegrationApplicationMap>;
  addCustomPage: (isPrivate: boolean) => Promise<void>;
  refreshPageState: () => Promise<void>;
  getProfileType: () => Promise<ProfileType>;
  setProfileType: (profileType: ProfileType.BWP_ALL | ProfileType.BWP_ONLY) => Promise<void>;
  refreshRouters: () => Promise<void>;
  refreshMembersAreaApps: () => Promise<void>;
  registerAdditionalWidgets: () => void;
  getAdditionalWidgets: () => void;
  installAdditionalWidgets: () => void;
  setProfileWidgetHeight: (height: number) => Promise<void>;
  configureMAApps: (configurationDetails: ConfigureMAAppsOptions) => Promise<void>;
  configureMAAppsAndOpenManagePages: (
    configurationDetails: ConfigureMAAppsOptions,
    referralInfo?: ReferralInfo,
  ) => Promise<void>;
  openGeneralSettingsPanel: (referralInfo: ReferralInfo) => Promise<void>;
  getMemberPrivacySettings: () => Promise<MemberPrivacySettings | undefined>;
}

export interface MembersAreaPagePublicApi {
  addWidgetsPlugins(
    widgets: {
      appDefinitionId: string;
      widgetId: string;
    }[],
  ): Promise<void>;
}
