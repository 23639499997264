import { EditorSDK, PageData, PageRef, RouterData } from '@wix/platform-editor-sdk';

import * as routersService from './routers';
import * as menusService from './menus';
import * as pagesWrapper from '../wrappers/pages';
import * as pagesGroupWrapper from '../wrappers/pagesGroup';
import * as tpaWrapper from '../wrappers/tpa';
import {
  MEMBERS_PAGES_GROUP_NAME,
  MEMBERS_PAGES_MIN_HEIGHT,
  SANTA_MEMBERS_APP_ID,
  UNDELETABLE_PAGES,
  SOCIAL_APPS_IDS,
  APP_TOKEN,
  MEMBERS_LIST_PAGE_ID,
  MEMBERS_LIST_APP_DEF_ID,
} from '../constants';
import { log } from '../../utils/monitoring';
import { onManagePages } from '../wrappers/panels';
import * as componentsWrapper from '../wrappers/components';
import { allSettled, retryPromise } from '../../utils/promises';
import { isAddMinHeightEnabled } from '../../utils/experiments';
import { isApplicationInstalled } from '../wrappers/tpa';
import { getMembersAreaRouters } from './routers';
import { MembersPage } from '../../types/EditorAppModule';
import { PagesModificationPayload as ConfigureMAAppsOptions, PageType } from '../../types/general-settings';
import { AddApplicationOrComponentResponse, FullAddApplicationResponse } from '../platform-api/addApplications';
import { removeMembersAreaPage, removeMembersAreaPageByPageId } from '../platform-api/removeMembersAreaPage';
import { getTranslationFunction } from '../../i18n';
import { maybeAddApplications } from '../platform-app/current-ma/public-api';
import { setProfileType } from './profile';
import { ReferralInfo } from '../../types/bi';

async function setProtectedPage({ editorSDK, page }: { editorSDK: EditorSDK; page: MembersPage }) {
  const existingPageData = await pagesWrapper.getPageData({ editorSDK, pageRef: page.pageData.pageRef! });
  if (existingPageData && existingPageData.pageSecurity && !existingPageData.pageSecurity.requireLogin) {
    await pagesWrapper.updatePageData({
      editorSDK,
      pageRef: page.pageData.pageRef!,
      pageData: { pageSecurity: { requireLogin: true } },
    });
  }
}

function setProtectedPages({ editorSDK, pages }: { editorSDK: EditorSDK; pages: MembersPage[] }) {
  return Promise.all(pages.map((page) => setProtectedPage({ editorSDK, page })));
}

function addPagesToPagesGroup({ editorSDK, pages }: { editorSDK: EditorSDK; pages: MembersPage[] }) {
  return Promise.all(
    pages.map((page) =>
      retryPromise(
        () => pagesGroupWrapper.addPageToGroup(editorSDK, MEMBERS_PAGES_GROUP_NAME, page.pageData.pageRef?.id!),
        { delayMs: 200, message: 'Retry adding page to a pages group' },
      ),
    ),
  );
}

function updatePageWithManagingAppDefId({ editorSDK, pageRef }: { editorSDK: EditorSDK; pageRef: PageRef }) {
  return pagesWrapper.updatePageData({ editorSDK, pageRef, pageData: { managingAppDefId: SANTA_MEMBERS_APP_ID } });
}

async function connectPagesToMembers({ editorSDK, pages }: { editorSDK: EditorSDK; pages: MembersPage[] }) {
  const { publicRouter, privateRouter } = await routersService.getMembersAreaRouters(editorSDK);
  const privatePages = pages.filter((page) => page.pageData.isPrivate);
  const publicPages = pages.filter((page) => !page.pageData.isPrivate);
  const includesPrivatePages = privatePages.length > 0;
  const includesPublicPages = publicPages.length > 0;

  await Promise.all(
    [
      includesPrivatePages &&
        routersService.connectPagesToRouter({ editorSDK, pages: privatePages, router: privateRouter }),
      includesPublicPages &&
        routersService.connectPagesToRouter({ editorSDK, pages: publicPages, router: publicRouter }),
      includesPrivatePages && setProtectedPages({ editorSDK, pages: privatePages }),
    ].filter((p) => !!p) as Promise<void[]>[],
  );

  try {
    await addPagesToPagesGroup({ editorSDK, pages });
  } catch (error) {
    log('Failed to add new page to the pages group, SOSP will not be visible in the page', {
      extra: { error: (error as Error).toString(), stack: (error as Error)?.stack },
    });
  }

  return menusService.connectPagesToMenus({ editorSDK, pages });
}

async function setStateForPages(editorSDK: EditorSDK) {
  const applicationPages = await editorSDK.document.pages.getApplicationPages(APP_TOKEN);

  const readOnly: PageRef[] = [];
  const deletable: PageRef[] = [];

  applicationPages.forEach((pageData) => {
    const pageRef = { id: pageData.id, type: 'DESKTOP' };
    if (UNDELETABLE_PAGES && UNDELETABLE_PAGES.includes(pageData.tpaPageId!)) {
      readOnly.push(pageRef as PageRef);
    } else {
      deletable.push(pageRef as PageRef);
    }
  });

  await editorSDK.document.pages.setState('', {
    state: {
      readOnly,
      deletable,
    },
  });
  return editorSDK.document.application.reloadManifest();
}

async function getPageByIntegrationApp({
  editorSDK,
  app,
}: {
  editorSDK: EditorSDK;
  app: { pageId: string; appDefinitionId: string };
}) {
  const { pageId, appDefinitionId } = app;
  const allPages = await pagesWrapper.getAllPages({ editorSDK });

  let page: PageData | undefined, applicationId: number | undefined;
  if (appDefinitionId) {
    const appData = await tpaWrapper.getDataByAppDefId({ editorSDK, appDefinitionId });
    applicationId = appData?.applicationId;
    if (!applicationId) {
      log('Invalid getDataByAppDefId', { extra: { appDefinitionId, source: 'getPageByIntegrationApp', app } });
    }
  }

  if (applicationId) {
    page = allPages.find((onePage) => onePage.tpaPageId === pageId && onePage.tpaApplicationId === applicationId);
  } else {
    page = allPages.find((onePage) => onePage.tpaPageId === pageId);
  }
  return page;
}

async function getAllMembersPagesRefs({ editorSDK }: { editorSDK: EditorSDK }) {
  const { publicRouter = {}, privateRouter = {} } = await routersService.getMembersAreaRouters(editorSDK);
  const allMAPages = [...((publicRouter as RouterData).pages || []), ...((privateRouter as RouterData).pages || [])];
  const allMAPagesRefs = allMAPages.map((page) => page.pageRef);
  return allMAPagesRefs;
}

async function getApplicationPage(editorSDK: EditorSDK, appDefinitionId: string, pageId: string) {
  const allPages = await pagesWrapper.getAllPages({ editorSDK });
  const pageData = await tpaWrapper.getDataByAppDefId({ editorSDK, appDefinitionId });
  return allPages?.find((page) => pageData?.applicationId === page.tpaApplicationId && page.tpaPageId === pageId);
}

async function addMinHeight(
  addedApps: (AddApplicationOrComponentResponse | { pageRef: PageRef })[],
  editorSDK: EditorSDK,
) {
  const shouldUpdateMinedHeight = await isAddMinHeightEnabled();
  if (!shouldUpdateMinedHeight) {
    return addedApps;
  }
  const addHeightPromises = (addedApps as FullAddApplicationResponse[])
    .filter((app) => app.pageRef)
    .map(({ pageRef }) => {
      return componentsWrapper.updateProperties({ editorSDK, pageRef, props: MEMBERS_PAGES_MIN_HEIGHT });
    });
  return allSettled<any>(addHeightPromises).then(() => addedApps);
}

async function hasSocialPages(editorSDK: EditorSDK) {
  for (const appDefinitionId of SOCIAL_APPS_IDS) {
    if (await isApplicationInstalled({ editorSDK, appDefinitionId })) {
      return true;
    }
  }

  const { publicRouter } = await getMembersAreaRouters(editorSDK);
  const hasSocialPagesInRouter = Object.keys(publicRouter?.config?.patterns ?? {}).length > 0;

  return hasSocialPagesInRouter;
}

const removeMembersList = async (editorSDK: EditorSDK, shouldDisableTransaction?: boolean) => {
  const pageData = await getPageByIntegrationApp({
    editorSDK,
    app: { pageId: MEMBERS_LIST_PAGE_ID, appDefinitionId: MEMBERS_LIST_APP_DEF_ID },
  });

  await updatePageWithManagingAppDefId({ editorSDK, pageRef: { id: pageData?.id! } });

  await removeMembersAreaPageByPageId({
    appDefinitionId: MEMBERS_LIST_APP_DEF_ID,
    pageId: MEMBERS_LIST_PAGE_ID,
    editorSDK,
    shouldDisableTransaction,
  });
};

const configureMAApps = async (
  editorSDK: EditorSDK,
  { appsToInstall, appsToRemove, profilePageType }: ConfigureMAAppsOptions,
) => {
  let currentStep = 0;
  const totalSteps = appsToInstall.length + appsToRemove.length + (profilePageType ? 1 : 0);
  const t = await getTranslationFunction(editorSDK, true);

  if (!totalSteps) {
    return;
  }

  await editorSDK.editor.openProgressBar(APP_TOKEN, {
    title: t('GeneralSettings_ProgressModal_Title'),
    totalSteps,
    stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
  });

  try {
    if (appsToInstall.length) {
      await maybeAddApplications(
        appsToInstall.map(({ integrationApplication }) => integrationApplication),
        false,
      );
      await editorSDK.editor.updateProgressBar(APP_TOKEN, {
        currentStep: (currentStep += appsToInstall.length),
        stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
      });
    }

    if (appsToRemove.length) {
      for (const { pageType, integrationApplication } of appsToRemove) {
        const isMembersListPage = integrationApplication.appDefinitionId === MEMBERS_LIST_APP_DEF_ID;
        const shouldDisableTransaction = true;

        if (pageType === PageType.StandAlone && isMembersListPage) {
          await removeMembersList(editorSDK, shouldDisableTransaction);
        } else if (pageType === PageType.TPA) {
          await removeMembersAreaPageByPageId({ ...integrationApplication, editorSDK, shouldDisableTransaction });
        } else {
          await removeMembersAreaPage({ editorSDK, id: integrationApplication.pageId, shouldDisableTransaction });
        }

        await editorSDK.editor.updateProgressBar(APP_TOKEN, {
          currentStep: ++currentStep,
          stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
        });
      }
    }

    if (profilePageType) {
      await setProfileType(editorSDK, profilePageType);
      await editorSDK.editor.updateProgressBar(APP_TOKEN, {
        currentStep: ++currentStep,
        stepTitle: t('GeneralSettings_ProgressModal_Subtitle'),
      });
    }

    await editorSDK.editor.closeProgressBar(APP_TOKEN, {
      isError: false,
    });
  } catch (e) {
    await editorSDK.editor.closeProgressBar(APP_TOKEN, {
      isError: true,
    });

    throw e;
  }
};

const configureMAAppsAndOpenManagePages = async (
  editorSDK: EditorSDK,
  configurationOptions: ConfigureMAAppsOptions,
  referralInfo?: ReferralInfo,
) => {
  await configureMAApps(editorSDK, configurationOptions);
  onManagePages({ editorSDK, eventPayload: { pageRef: null, referralInfo } });
};

export {
  connectPagesToMembers,
  updatePageWithManagingAppDefId,
  setStateForPages,
  getPageByIntegrationApp,
  getApplicationPage,
  getAllMembersPagesRefs,
  addMinHeight,
  hasSocialPages,
  removeMembersList,
  configureMAApps,
  configureMAAppsAndOpenManagePages,
};
